import React, { Ref, useState, useMemo } from 'react';
import { Link } from 'react-style-guide';
import {
  Thumbnail2d,
  ThumbnailTypes,
  ThumbnailGameThumbnailSize,
  ThumbnailFormat
} from 'roblox-thumbnails';
import { TranslateFunction } from 'react-utilities';
import classNames from 'classnames';
import GameTilePlayButton from './GameTilePlayButton';
import browserUtils from '../utils/browserUtils';
import { TGameData, TGetFriendsResponse } from '../types/bedev1Types';
import {
  GridTileFacepileFooter,
  GameTileRatingFooter,
  TBuildEventProperties,
  GameTilePlayerCountPill,
  GameTileIconWithTextFooter
} from './GameTileUtils';
import { debounce } from '../utils/helperUtils';
import configConstants from '../constants/configConstants';
import { getInGameFriends, getFriendVisits } from '../utils/parsingUtils';
import { TPlayerCountStyle } from '../types/bedev2Types';

export type TGridTileProps = {
  gameData: TGameData;
  id: number;
  buildEventProperties: TBuildEventProperties;
  friendData?: TGetFriendsResponse[];
  playerCountStyle?: TPlayerCountStyle;
  translate: TranslateFunction;
};

const GridTile = React.forwardRef(
  (
    {
      gameData,
      id,
      buildEventProperties,
      friendData = [],
      playerCountStyle,
      translate
    }: TGridTileProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const isFirstTile = id === 0;
    const isLastTile = id === configConstants.homePage.maxGridTilesPerCarouselPage - 1;
    const [isFocused, setIsFocused] = useState(false);

    const [setFocusDebounced, cancelSetFocusDebounced] = debounce(() => {
      setIsFocused(true);
    }, 100);

    const [setFocusLostDebounced, cancelSetFocusLostDebounced] = debounce(() => {
      setIsFocused(false);
    }, 100);

    const onFocus = () => {
      cancelSetFocusLostDebounced();
      setFocusDebounced();
    };

    const onFocusLost = () => {
      cancelSetFocusDebounced();
      setFocusLostDebounced();
    };

    const gameReferralUrl = browserUtils.buildGameDetailUrl(
      gameData.placeId,
      gameData.name,
      buildEventProperties(gameData, id)
    );

    const clientReferralUrl = buildEventProperties
      ? browserUtils.buildGameDetailUrl(
          gameData.placeId,
          gameData.name,
          buildEventProperties(gameData, id)
        )
      : gameReferralUrl;

    const playButtonEventProperties = buildEventProperties(gameData, id) as Record<
      string,
      string | number | undefined
    >;

    const friendsInGame = useMemo(() => getInGameFriends(friendData, gameData.universeId), [
      friendData,
      gameData.universeId
    ]);

    const friendVisits = useMemo(() => getFriendVisits(friendData, gameData.friendVisits), [
      friendData,
      gameData.friendVisits
    ]);

    const getBaseTileMetadata = (): JSX.Element => {
      if (friendsInGame?.length > 0) {
        return <GridTileFacepileFooter friendsData={friendsInGame} isOnline />;
      }
      if (friendVisits?.length > 0) {
        return <GridTileFacepileFooter friendsData={friendVisits} isOnline={false} />;
      }
      if (gameData.friendVisitedString) {
        return (
          <GameTileIconWithTextFooter
            iconClassName='icon-pastname'
            text={gameData.friendVisitedString}
          />
        );
      }
      return (
        <GameTileRatingFooter
          totalUpVotes={gameData.totalUpVotes}
          totalDownVotes={gameData.totalDownVotes}
          translate={translate}
        />
      );
    };

    const getHoverTileMetadata = (): JSX.Element | null => {
      if (gameData.minimumAge && gameData.ageRecommendationDisplayName) {
        return (
          <div className='game-card-info' data-testid='game-tile-hover-age-rating'>
            <span className='info-label'>{gameData.ageRecommendationDisplayName}</span>
          </div>
        );
      }
      return null;
    };

    const getGameTileMetadata = (): JSX.Element => {
      return (
        <div className='grid-tile-metadata'>
          <div className='base-metadata'>{getBaseTileMetadata()}</div>
          <div className='hover-metadata'>{getHoverTileMetadata()}</div>
        </div>
      );
    };

    return (
      <li
        className={classNames(
          'list-item',
          'hover-game-tile',
          'grid-tile',
          { 'first-tile': isFirstTile },
          { 'last-tile': isLastTile },
          { focused: isFocused }
        )}
        data-testid='grid-tile'
        onMouseOver={onFocus}
        onMouseLeave={onFocusLost}
        onFocus={onFocus}
        onBlur={onFocusLost}
        id={gameData.universeId.toString()}>
        {gameData.universeId && (
          <div className='featured-game-container game-card-container' ref={ref}>
            <Link url={clientReferralUrl} className='game-card-link'>
              <div className='featured-game-icon-container'>
                <Thumbnail2d
                  type={ThumbnailTypes.gameThumbnail}
                  size={ThumbnailGameThumbnailSize.width384}
                  targetId={gameData.placeId}
                  containerClass='brief-game-icon'
                  format={ThumbnailFormat.jpeg}
                  altName={gameData.name}
                />
                <GameTilePlayerCountPill
                  playerCount={gameData.playerCount}
                  playerCountStyle={playerCountStyle}
                />
              </div>

              <div className='info-container'>
                <div className='game-card-name game-name-title' title={gameData.name}>
                  {gameData.name}
                </div>
                {getGameTileMetadata()}
              </div>
            </Link>

            {isFocused && (
              <div data-testid='game-tile-hover-game-tile-contents' className='game-card-contents'>
                <GameTilePlayButton
                  universeId={gameData.universeId.toString()}
                  placeId={gameData.placeId.toString()}
                  playButtonEventProperties={playButtonEventProperties}
                  buttonClassName='btn-growth-xs play-button'
                  purchaseIconClassName='icon-robux-white'
                  clientReferralUrl={clientReferralUrl}
                  shouldPurchaseNavigateToDetails
                />
              </div>
            )}
          </div>
        )}
      </li>
    );
  }
);

GridTile.displayName = 'HoverGameTile';
export default GridTile;
